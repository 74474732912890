import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`This article briefly explains how to enable deprecation, intervention & crash reports as well as network error logging with new browser API. It is pretty much a TL;DR version of `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://scotthelme.co.uk/introducing-the-reporting-api-nel-other-major-changes-to-report-uri/"
        }}>{`scotthelme.co.uk`}</MDXTag>{` article and reason for writing it in a first place is for me to understand this topic better. I hope you like this short version too and once interested you will subscribe to Scott's blog :`}{`)`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Reporting API`}</MDXTag>
      <MDXTag name="p" components={components}>{`Reporting API, which is currently in a status of `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.w3.org/TR/reporting/"
        }}>{`working draft`}</MDXTag>{`, allows applications to tell browsers to report things like deprecation warnings to a specified url. Currently Reporting API supports following reporting types:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Deprecation - reports soon to be deprecated api. For example `}<MDXTag name="inlineCode" components={components} parentName="li">{`WebSQL is deprecated and will be removed in Chrome 97 around January 2020`}</MDXTag>{`.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Intervention - reports when browser blocks request due to security or performance reasons. `}<MDXTag name="inlineCode" components={components} parentName="li">{`A request to play audio was blocked because it was not triggered by user activation (such as a click)`}</MDXTag>{`.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Crashes - reports browser crashes & when possible provides a reason. At the time of writing this only `}<MDXTag name="inlineCode" components={components} parentName="li">{`out-of-memory crash`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="li">{`oom`}</MDXTag>{` reason was supported.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}><MDXTag name="inlineCode" components={components} parentName="h3">{`Report-To`}</MDXTag>{` header`}</MDXTag>
      <MDXTag name="p" components={components}>{`In order to enable reporting api you need to set a response header like following:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-plaintext"
        }}>{`Report-To: {
 "group":"default",
 "max_age":604800,
 "endpoints":[{
   "url":"https://endpoint/path"
 }],
 "include_subdomains":true
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`For deprecation/intervention/crash report types you should use `}<MDXTag name="inlineCode" components={components} parentName="p">{`default`}</MDXTag>{` for group name, so we can leave it alone. `}<MDXTag name="inlineCode" components={components} parentName="p">{`max_age`}</MDXTag>{` tells browser to remember to send requests to specified endpoints for given time in seconds (in the example it is set to 7 days). You can specify multiple endpoints if you wish.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Network error logging`}</MDXTag>
      <MDXTag name="p" components={components}>{`Network error logging, which is also in a status of `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.w3.org/TR/network-error-logging/"
        }}>{`working draft`}</MDXTag>{`, allows to report issues with:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`dns `}<MDXTag name="inlineCode" components={components} parentName="li">{`unreachable, name_not_resolved, ...`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`tcp `}<MDXTag name="inlineCode" components={components} parentName="li">{`timed_out, refused, ...`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`tls `}<MDXTag name="inlineCode" components={components} parentName="li">{`cert.name_invalid, cert.authority_invalid, ...`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`http `}<MDXTag name="inlineCode" components={components} parentName="li">{`response.invalid, response.redirect_loop, ...`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`abandoned`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`unknown`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Full list with descriptions can be found on the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.w3.org/TR/network-error-logging/#dns-resolution-errors"
        }}>{`specification page`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h3" components={components}><MDXTag name="inlineCode" components={components} parentName="h3">{`NEL`}</MDXTag>{` header`}</MDXTag>
      <MDXTag name="p" components={components}>{`In order to enable network error logging you need to set a response header like following:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-plaintext"
        }}>{`NEL: {
  "report_to":"default",
  "max_age":604800,
  "include_subdomains":true
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As you can see there is no endpoints property. `}<MDXTag name="inlineCode" components={components} parentName="p">{`Report-To`}</MDXTag>{` should be specified for this header, because `}<MDXTag name="inlineCode" components={components} parentName="p">{`NEL`}</MDXTag>{` uses endpoints from it to report data.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Working with reports & `}<MDXTag name="inlineCode" components={components} parentName="h2">{`report-uri.com`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`You could build your own dashboard which handles reports for mentioned APIs. Sample report data structure is shown on previously mentioned specification pages. However there is a free to use service called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://report-uri.com/"
        }}>{`Report URI`}</MDXTag>{` which already handles these and other type of reports. It takes 5-10 minutes to set it up and as long as it is easy for you to add headers to your site, it should be very easy to start gathering data from Reporting & Network Error Logging APIs. It took it just about 30 minutes for me to add it to my site & I encourage you to do the same.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Thanks for reading & have fun.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Getting started with Reporting API, Network Error Logging & Report URI",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "copyright": "Photo by rawpixel on Unsplash",
  "date": "2018-10-28T11:48:10.000Z",
  "overview": "Quick look at Reporting API which is supported since Chrome M70, deprecation, intervention & crash reports, network error logging and Report URI dashboard.",
  "type": "post"
};
    